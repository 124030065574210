import React from 'react'

import IndexLayout from '../../layouts';

import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';


import InscriptionHero from '../../components/Inscription/InscriptionHero/InscriptionHero';
import { LocaleTypes } from '../../data/locales';
import InscriptionHonduras from '../../components/Inscription/InscriptionHonduras/InscriptionHonduras';
import { FormattedMessage } from 'react-intl';
interface hondurasProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const honduras: React.FC<hondurasProps> = ({pathContext, location}) => {

  const content = () => (
    <>
      <SEO titleId="inscription.psp.title" descriptionId="inscription.psp.description" />
      <InscriptionHero>
        <h1>
          <FormattedMessage id="inscription.psp.title" />
        </h1>
        <p>
          <FormattedMessage id="inscription.psp.description" />
        </p>
      </InscriptionHero>
      <InscriptionHonduras />
      <Footer theme="primary" />
    </>
  )
  return (
   <IndexLayout 
      render={ content }
      locale={pathContext.localeCode}
      navbarTheme="light"
      location={location}
    /> 
  )
}

export default honduras